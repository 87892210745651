.login {
  position: relative;
  height: 100vh;
  padding: var(--spacing-20) 0;
}

.login .cover-img {
  z-index: -1;
}

.login__title {
  margin-bottom: 24px;
  text-align: center;
}

.login__logo-container {
  display: flex;
  gap: 8px;
  margin-bottom: 40px;
}

.login__logo {
  height: 56px;
  width: auto;
  max-width: 100%;
}
