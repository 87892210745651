.footer {
  padding: var(--spacing-10) 0 var(--spacing-5);
  margin-top: auto;
  overflow: hidden;
}

.footer__container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 40px;
}

.footer__left {
  flex: 1 0 auto;
  max-width: 593px;
}

.footer__left .newsletter-inline {
  min-height: 102px;
}

.footer__right {
  display: flex;
  justify-content: flex-end;
  flex: 0 1 auto;
}

.footer__logo {
  margin-bottom: 21px;
}

.footer__logo-img {
  max-width: 100%;
  width: auto;
  height: 59px;
}

.footer__list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1) var(--spacing-5);
  margin-top: var(--spacing-5);
}

@media only screen and (max-width: 1149px) {
  .footer {
    padding: var(--spacing-5) 0;
  }

  .footer__container {
    flex-direction: column;
    align-items: center;
  }

  .footer__left {
    width: 100%;
    max-width: 500px;
  }

  .footer__list {
    justify-content: center;
  }

  .footer__logo {
    margin-bottom: 0;
  }

  .footer__logo-img {
    height: 28px;
  }

  .footer__left .newsletter-inline {
    min-height: 195px;
  }
}
