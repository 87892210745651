.title {
  text-align: center;
}

.title__label {
  color: var(--color-brand-lime);
}

.title__title {
  max-width: 1024px;
  margin: 0 auto;
}

.title__title span {
  text-decoration: underline;
  text-decoration-color: var(--color-brand-lime);
}
