.custom-dropdown {
  position: relative;
}

.custom-dropdown__menu {
  position: absolute;
  top: calc(100% + 16px);
  width: max-content;
  max-height: 195px;
  color: white;
  text-align: left;
  opacity: 0;
  background: var(--glass100);
  backdrop-filter: blur(8px);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  visibility: hidden;
  transition:
    transform var(--transition),
    opacity var(--transition);
  transform: translateY(6px);
  list-style: none;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  z-index: 2;
}

.custom-dropdown__menu::-webkit-scrollbar {
  display: none;
}

.custom-dropdown__menu--visible {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}

.custom-dropdown__menu .custom-dropdown__item {
  position: relative;
  display: block;
  padding: 17px 15px;
  font-size: 1.8rem;
  line-height: 1.15;
  letter-spacing: 0.1em;
  font-family: 'Albert', sans-serif;
  font-weight: 600;
  cursor: pointer;
  outline-offset: -4px;
}

.custom-dropdown__item:hover {
  background-color: var(--glass100);
}

.custom-dropdown__item:focus-visible {
  outline: 2px dashed white;
}
