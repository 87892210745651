.home-banner {
  display: flex;
  align-items: center;
  position: relative;
  padding: calc(var(--header-height) + 100px) 0 100px;
  min-height: calc(var(--vh, 1vh) * 100);
  text-align: center;
}

.home-banner strong {
  display: inline-block;
  line-height: 0.8;
  font-weight: 300;
}

.home-banner__cta {
  margin-top: 80px;
}

.home-banner__desc {
  margin-top: var(--spacing-2);
}

@media only screen and (max-width: 767px) {
  .home-banner {
    padding: calc(var(--header-height) + 55px) 0 80px;
  }

  .home-banner strong {
    line-height: 1;
  }

  .home-banner__cta {
    margin-top: 40px;
  }
}
