.audience__container {
  display: flex;
  align-items: center;
}

.audience__items {
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) {
  .audience__container {
    padding: 200px 0;
    min-height: 100vh;
  }

  .audience__items {
    gap: var(--spacing-10);
  }
}

@media only screen and (max-width: 767px) {
  .audience__container {
    padding: var(--spacing-10) 0;
  }

  .audience__items {
    gap: var(--spacing-5);
  }
}
