.pg-footer {
  margin-top: auto;
  padding: var(--spacing-10) 0 var(--spacing-3);
}

.pg-footer__list {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: var(--spacing-2) var(--spacing-5);
  font-size: 1.8rem;
  text-decoration: underline;
  letter-spacing: 0.1em;
  line-height: 1.15;
}

@media only screen and (max-width: 767px) {
  .pg-footer .wrap {
    padding: 0 var(--spacing-3);
  }
}
