#modal {
  position: absolute;
  top: 0;
  left: 0;
  text-align: left;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 110;
}

.modal:not(.right) {
  overflow-y: auto;
}

.modal__overflow {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 120px 0;
  width: 100%;
  min-height: 100%;
}

.modal__overflow::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('../../assets/grain.png');
  opacity: 0.4;
}

.modal__overflow > div {
  max-width: 800px;
  width: calc(100% - 32px);
}

.modal.lg .modal__overflow > div {
  max-width: 1000px;
}

.modal.bottom .modal__overflow {
  padding: 0;
  height: 100%;
  overflow: hidden;
}

.modal__container {
  position: relative;
  padding: var(--spacing-10);
  background: var(--glass100);
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.12);
  color: white;
  overflow: hidden;
  z-index: 0;
}

.modal.bottom .modal__container,
.modal.right .modal__container {
  max-height: 100%;
  overflow-y: auto;
}

.right .modal__container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 800px;
}

.bottom .modal__container {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 100%;
}

.modal__close {
  position: absolute;
  top: 16px;
  right: 16px;
  padding: var(--spacing-2);
}

.modal__close svg {
  width: 32px;
  height: 32px;
  transform: rotate(45deg);
}

.modal-enter .modal__container {
  opacity: 0;
}
.modal-enter-active .modal__container {
  opacity: 1;
  transition: 300ms;
}
.modal-exit .modal__container {
  opacity: 1;
}
.modal-exit-active .modal__container {
  opacity: 0;
  transition: 300ms;
}

.modal-enter.right .modal__container {
  transform: translateX(100%);
}
.modal-enter-active.right .modal__container {
  transform: translateX(0);
  transition: 300ms;
}
.modal-exit.right .modal__container {
  transform: translateX(0);
}
.modal-exit-active.right .modal__container {
  transform: translateX(100%);
  transition: 300ms;
}

.modal-enter.bottom .modal__container {
  transform: translateY(100%);
}
.modal-enter-active.bottom .modal__container {
  transform: translateY(0);
  transition: 300ms;
}
.modal-exit.bottom .modal__container {
  transform: translateY(0);
}
.modal-exit-active.bottom .modal__container {
  transform: translateY(100%);
  transition: 300ms;
}

@media only screen and (max-width: 899px) {
  .right .modal__container {
    width: calc(var(--vw) * 100);
  }

  .modal__close {
    top: 4px;
    right: 4px;
    padding: var(--spacing-1);
  }

  .modal__container {
    padding: var(--spacing-5);
  }
}

.keep-mounted-closed {
  display: none;
}
