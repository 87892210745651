.table-container {
  width: 100%;
  overflow-x: auto;
}

table {
  width: 100%;
}

td {
  padding: 8px 16px 8px 0;
}

td:last-of-type,
th:last-of-type {
  padding-right: 0;
}

th {
  padding: 0 16px 16px 0;
}

tr:not(:first-of-type):not(:last-of-type) {
  border-bottom: 1px solid white;
}

tr:first-of-type {
  font-size: 2.4rem;
  line-height: 1.15;
  letter-spacing: -0.05em;
  opacity: 0.8;
}
