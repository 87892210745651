.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 16px 0;
  z-index: 100;
  transition: color var(--transition);
}

.header.scrolled {
  position: fixed;
  color: var(--text);
  z-index: 100;
}

.header.scrolled::after {
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
  transition: var(--transition);
}

.header.scrolled .link:hover {
  border-color: var(--text);
}

.header.scrolled .user-dropdown__avatar {
  color: var(--text);
}

.header__nav {
  display: flex;
  align-items: center;
}

.header__logo {
  flex: 0 1 auto;
  margin-right: 40px;
  height: 4rem;
  min-width: 0;
}

.header__logo-img {
  height: 100%;
  width: auto;
  max-width: 100%;
}

.header__nav-list {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.header .header__nav-list + .custom-dropdown,
.header .header__burger + .custom-dropdown {
  margin-left: 0;
}

.header__nav-item {
  margin-left: 40px;
}

.header__burger {
  margin-left: auto;
}

.header__close {
  position: fixed;
  top: 20px;
  right: 12px;
  padding: 10px;
  z-index: 1;
}

.header__mobile-nav {
  display: flex;
  flex-direction: column;
  padding: 24px;
  height: 100%;
}

.header__back {
  margin-right: auto;
}

.header .user-dropdown__avatar {
  margin-left: 32px;
}

.header .custom-dropdown {
  margin-left: auto;
}

.header__logo--mobile {
  position: fixed;
  top: 28px;
  left: 24px;
  width: auto;
}

.header__logo-symbol {
  height: 24px;
}

@media only screen and (max-width: 767px) {
  .header > .wrap {
    padding: 0 24px;
  }

  .header__logo {
    height: 2.4rem;
    margin-right: 24px;
  }

  .header__nav-list {
    flex-direction: column;
    gap: 36px;
    margin: 0;
    padding: 80px 0;
  }

  .header__nav-item {
    margin: 0;
    font-size: 1.6rem;
  }

  .header__nav-link {
    font-size: 2rem;
  }
}
