.user-dropdown__avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: var(--glass);
  transition: var(--transition);
  color: white;
  backdrop-filter: blur(4px);
  overflow: hidden;
}

.user-dropdown__avatar:hover {
  background-color: var(--glass100);
}

.user-dropdown__avatar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  user-select: none;
  pointer-events: none;
}

.user-dropdown__avatar svg {
  width: 71%;
  height: 71%;
  align-self: flex-end;
  user-select: none;
  pointer-events: none;
}

@media only screen and (max-width: 767px) {
  .user-dropdown__avatar {
    width: 48px;
    height: 48px;
  }
}
