.newsletter-inline {
  position: relative;
}

.newsletter-inline .hs-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: var(--spacing-1) var(--spacing-2);
}

.newsletter-inline .hs_email {
  flex: 1 1 296px;
  margin: 0;
  line-height: 1.5;
}

.newsletter-inline label {
  display: block;
}

.newsletter-inline .hs_submit {
  flex: 1 0 auto;
}

.newsletter-inline .submitted-message {
  padding: 0;
  font-size: 2rem;
}

.newsletter-inline .hs_error_rollup {
  display: none;
}

.newsletter-inline .hs-error-msg {
  margin: 0;
}

.newsletter-inline .hs-error-msgs {
  margin-top: var(--spacing-1);
  padding: 7px 15px;
  width: 100%;
  border: 1px solid;
  border-radius: 8px;
  font-size: 2rem;
  line-height: 1.5;
  background-color: var(--error);
  color: var(--error-text);
  border-color: var(--error200);
}

.newsletter-inline .hs-input {
  width: 100%;
  padding: 17px 11px;
  font-size: 2.4rem;
  line-height: 1.15;
  background-color: transparent;
  color: white;
  font-family: 'Albert', Sans-Serif;
  border: 1px solid white;
}

.newsletter-inline .hs-input:focus-visible {
  padding: 16px 10px;
  border-width: 2px;
}

.newsletter-inline .hs-input::placeholder {
  color: white;
  opacity: 0.5;
}

@media only screen and (max-width: 1149px) {
  .newsletter-inline .hs-form {
    flex-direction: column;
    align-items: center;
    gap: 0;
  }

  .newsletter-inline .hs_email {
    flex: 0 0 auto;
    width: 100%;
  }

  .newsletter-inline .hs_submit {
    width: 100%;
  }
}
