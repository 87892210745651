.commitment {
  display: flex;
  align-items: center;
  position: relative;
  padding: 200px 0;
  background: var(--blue3-gradient);
  min-height: 100vh;
}

.commitment__label {
  margin-bottom: var(--spacing-5);
}

.commitment__title strong {
  position: relative;
  display: inline-block;
  font-weight: 500;
  z-index: 0;
}

.commitment__title strong svg {
  position: absolute;
  top: 0.7rem;
  left: 0.5rem;
  width: 102%;
  height: 118%;
  stroke-dashoffset: 1500;
  stroke-dasharray: 400%;
  z-index: -1;
  transition: 1s ease-in-out;
}

.commitment__title.visible strong svg {
  stroke-dashoffset: 0;
}

@media only screen and (max-width: 767px) {
  .commitment {
    padding: 80px 0;
  }

  .commitment__title strong svg {
    top: -0.5rem;
    left: -1.8rem;
    width: 121%;
    height: 141%;
  }

  .commitment__label {
    margin-bottom: var(--spacing-3);
  }
}
