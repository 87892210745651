.h1,
.h2,
.h3,
.h4,
.h5,
.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4,
.markdown h5 {
  font-weight: 500;
  letter-spacing: -0.04em;
}

.accent {
  font-family: 'Neuton', sans-serif;
  font-weight: 300;
  letter-spacing: -0.02em;
}

.h1,
.markdown h1 {
  font-size: 9.6rem;
  line-height: 1;
}

.h1.accent {
  font-size: 11.2rem;
  line-height: 0.8;
}

.h2 {
  font-size: 7.2rem;
  line-height: 1;
}

.h2.accent {
  font-size: 8.4rem;
  line-height: 0.8;
}

.h3,
.markdown h2 {
  font-size: 4.8rem;
  line-height: 1.15;
}

.h4,
.markdown h3 {
  font-size: 2.8rem;
  line-height: 1.25;
}

.body-text {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: -0.04em;
}

.overline {
  font-size: 2.2rem;
  line-height: 1.15;
  letter-spacing: 0.1em;
  font-weight: 500;
}

.inline-link {
  font-size: 2.4rem;
  line-height: 1.15;
  letter-spacing: -0.04em;
  text-decoration: underline;
}

.tag {
  display: inline-block;
  font-size: 2.4rem;
  line-height: 1.15;
  font-weight: 500;
  padding: 2px 12px;
  color: white;
  background-color: rgba(0, 0, 0, 0.2);
}

.tag--success,
.tag--error,
.tag--warning {
  border-width: 0 0 0 8px;
  border-style: solid;
}

.tag--success {
  border-color: var(--success);
}

.tag--error {
  border-color: var(--error);
}

.tag--warning {
  border-color: var(--warning);
}

/*  */
/* Markdown */
/*  */

.markdown h2 {
  margin-bottom: 40px;
}

.markdown ol:not(:last-child),
.markdown ul:not(:last-child),
.markdown blockquote:not(:last-child),
.markdown p:not(:last-child) {
  margin: 0 0 30px;
}

.markdown ol li:not(:last-child),
.markdown ul li:not(:last-child) {
  margin: 0 0 20px;
}

.markdown a {
  color: var(--primary);
  text-decoration: underline;
}

.markdown ol,
.markdown ul {
  padding: 0;
}

.markdown ol li {
  list-style: decimal;
  padding-left: 1rem;
  list-style-position: inside;
}

.markdown ul li {
  list-style: disc;
  list-style-position: inside;
}

.markdown li ol,
.markdown li ul {
  margin: 20px 0;
}

.markdown .video-container {
  margin: 40px auto;
}

@media only screen and (max-width: 767px) {
  .h1,
  .markdown h1 {
    font-size: 4.4rem;
  }

  .h1.accent {
    font-size: 5.2rem;
  }

  .h2 {
    font-size: 4rem;
  }

  .h2.accent {
    font-size: 3.8rem;
  }

  .h3,
  .markdown h2 {
    font-size: 2.8rem;
    line-height: 1;
  }

  .h4,
  .markdown h3 {
    font-size: 2.2rem;
  }

  .body-text {
    font-size: 1.8rem;
  }

  .overline {
    font-size: 1.6rem;
    line-height: 1.25;
  }

  .inline-link {
    font-size: 1.8rem;
  }
}
