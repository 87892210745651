.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.form__section {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  background-color: var(--surface100);
}

.form__group {
  display: flex;
  align-items: flex-start;
  gap: 24px;
}

@media only screen and (max-width: 899px) {
  .form__group {
    flex-direction: column;
    gap: 16px;
  }
}
