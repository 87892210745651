.simple-loader {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--glass100);
  backdrop-filter: blur(4px);
  z-index: 200;
}

.simple-loader-enter {
  opacity: 0;
}

.simple-loader-enter-active {
  opacity: 1;
  transition: opacity 250ms;
  transition-delay: 500ms;
}

.simple-loader-exit {
  opacity: 1;
  transition-delay: 500ms;
}

.simple-loader-exit-active {
  opacity: 0;
  transition: opacity 250ms;
}

.simple-loader__symbol {
  background: url('../../assets/symbol.svg');
  background-size: contain;
  width: 80px;
  height: 80px;
  opacity: 0;
  animation:
    pageloader 6s infinite linear,
    fade-in 1s forwards;
  animation-delay: 1s, 1s;
}

@keyframes pageloader {
  0% {
    transform: rotate(0) scale(1);
  }
  50% {
    transform: rotate(180deg) scale(1.5);
  }
  100% {
    transform: rotate(360deg) scale(1);
  }
}

@media only screen and (max-width: 1279px) {
  .simple-loader__symbol {
    width: 64px;
    height: 64px;
  }
}

@media only screen and (max-width: 767px) {
  .simple-loader__symbol {
    width: 40px;
    height: 40px;
  }
}
