.audience__item {
  position: relative;
  flex: 1 0 calc((100% - var(--spacing-10)) / 2);
}

.audience__item-container {
  align-items: flex-start;
}

.audience__item-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

@media only screen and (max-width: 767px) {
  .audience__item {
    flex: 1 0 100%;
    min-width: auto;
  }

  .audience__item-footer {
    gap: 16px;
  }
}
