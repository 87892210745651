.btn {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 17px 23px;
  font-size: 2.4rem;
  line-height: 1.15;
  letter-spacing: -0.04em;
  font-weight: 600;
  border: 1px solid;
  outline-offset: 3px;
  text-align: center;
  font-family: 'Albert', sans-serif;
  transition:
    background var(--transition),
    transform var(--transition);
}

.btn:hover {
  padding: 16px 22px;
  border-width: 2px;
}

.btn .loader,
.link .loader {
  font-size: 0.3rem;
}

.btn--light {
  color: white;
  border-color: white;
  background: var(--glass);
  backdrop-filter: blur(4px);
}

.btn--light:hover,
.btn--light:focus-visible {
  background: var(--glass100);
}
.btn--green {
  color: var(--success-text);
  border-color: var(--success200);
  background: var(--success);
  backdrop-filter: blur(4px);
}

.btn--green:hover,
.btn--green:focus-visible {
  background: var(--success200);
}
.btn--red {
  color: var(--error-text);
  border-color: var(--error200);
  background: var(--error);
  backdrop-filter: blur(4px);
}

.btn--red:hover,
.btn--red:focus-visible {
  background: var(--error200);
}

.btn[disabled] {
  cursor: not-allowed;
  background-color: var(--glass100);
  opacity: 0.5;
}

.btn[disabled]:hover {
  transform: none;
}

.btn svg {
  flex-shrink: 0;
  transition: var(--transition);
}

.btn span {
  flex: 1;
}

.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 21px 0;
  font-size: 2rem;
  line-height: 1.15;
  letter-spacing: -0.04em;
  font-family: 'Albert', sans-serif;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  outline-offset: 2px;
}

.link:hover {
  padding: 21px 0 20px;
  border-bottom: 1px solid white;
}

.link--sm {
  padding: 2px 0;
  font-size: 1.4rem;
}

.link--sm:hover {
  padding: 2px 0 1px;
}

.slider__nav {
  display: flex;
  gap: var(--spacing-3);
}

.slider__arrow {
  padding: 17px;
  border: 1px solid currentColor;
  border-radius: 2px;
  outline-offset: -4px;
  transition: var(--transition);
}

.slider__arrow:focus-visible,
.slider__arrow:not([disabled]):hover {
  border-color: var(--text);
  background-color: var(--text);
  color: white;
}

.slider__arrow[disabled] {
  opacity: 0.5;
}

.slider__arrow--prev {
  transform: rotate(90deg);
}

.slider__arrow--next {
  transform: rotate(-90deg);
}

.app .hs-button,
.modal .hs-button {
  display: block;
  width: 100%;
  padding: 17px 23px;
  text-align: center;
  color: white;
  background-color: transparent;
  font-size: 2.4rem;
  line-height: 1.15;
  letter-spacing: 0.1em;
  font-weight: 600;
  border: 1px solid white;
  outline-offset: 3px;
}

.app .hs-button:hover,
.app .hs-button:focus-visible,
.modal .hs-button:hover,
.modal .hs-button:focus-visible {
  padding: 16px 22px;
  border-width: 2px;
  border-radius: 0;
  background: transparent;
}

.download-btn {
  padding: var(--spacing-2);
}

.download-btn:focus-visible {
  outline-offset: -2px;
}

.download-btn:hover path:nth-last-of-type(1) {
  animation: jump 1s infinite linear;
  transform-origin: center;
}

@keyframes jump {
  0% {
    transform: translateY(0) scaleX(1);
  }
  25% {
    transform: translateY(3px) scaleX(0.9);
  }
  50% {
    transform: translateY(0) scaleX(1);
  }
  75% {
    transform: translateY(-3px) scaleX(1.1);
  }
  100% {
    transform: translateY(0) scaleX(1);
  }
}

@media only screen and (max-width: 899px) {
  .slider__nav {
    gap: var(--spacing-2);
  }
}

@media only screen and (max-width: 767px) {
  .btn {
    gap: 16px;
    padding: 13px 15px;
    font-size: 1.8rem;
  }

  .btn:hover {
    padding: 12px 14px;
  }

  .btn--has-arrow {
    padding-right: 72px;
  }

  .link {
    padding: 13px 0;
  }

  .link:hover {
    padding: 13px 0 12px;
  }

  .link--sm {
    padding: 2px 0;
    font-size: 1.2rem;
  }

  .link--sm:hover {
    padding: 2px 0 1px;
  }

  .app .hs-button,
  .modal .hs-button {
    font-size: 1.6rem;
    padding: 14px 24px;
    margin-top: var(--spacing-2);
  }
}
