.user {
  padding: 177px 0 0;
}

.user__header {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 24px;
}

.user__img {
  flex: 0 0 160px;
  width: 0;
  height: 160px;
  object-fit: cover;
  border-radius: 50%;
}

svg.user__img {
  padding: 10px;
}

.user__title {
  flex: 1;
}

.user__title span {
  display: block;
}

.user__section {
  position: relative;
  margin-top: 24px;
  padding: 24px;
  background: rgba(0, 0, 0, 0.08);
}

.user__section-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
}

.user__section--light {
  background: var(--glass100);
}

.user__section-title {
  margin-bottom: 16px;
}

.user__section-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: 24px;
}

.user__bio,
.user__email {
  margin-top: var(--spacing-2);
}

.user__email {
  display: inline-block;
}

.user__subscriptions {
  margin-top: var(--spacing-10);
}

.subscription__header {
  display: flex;
  gap: var(--spacing-2);
}

.subscription__left {
  flex: 1 1 auto;
}

.subscription__right {
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  gap: 8px 16px;
}

.subscription__title {
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-2);
}

.subscription__title span {
  flex: 0 0 auto;
}

.subscription__info {
  margin-top: var(--spacing-2);
}

.subscription__renew {
  display: inline-flex;
  align-items: center;
  gap: 0.6em;
  text-decoration: underline;
  margin-left: 0.5rem;
  font-weight: inherit;
}

.subscription__options {
  padding: 21px;
  border: 1px solid white;
}

.subscription__options svg {
  user-select: none;
  pointer-events: none;
}

.subscription__options:hover {
  background: var(--glass100);
}

.subscription__details {
  margin-top: var(--spacing-3);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
}

.subscription__detail {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: var(--spacing-3);
  margin-top: 16px;
  font-size: 2.4rem;
  line-height: 1.15;
}

.subscription__modal {
  display: flex;
  flex-direction: column;
}

.subscription__modal-footer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}

.subscription__modal-footer .btn {
  flex: 1;
}

.subscription__warning {
  margin-top: var(--spacing-3);
}

.user__form {
  width: 100%;
}

.payment-history__table {
  margin-top: var(--spacing-5);
  width: 840px;
}

.payment-history__table td {
  max-width: 200px;
}

.payment-history .loader {
  position: absolute;
  top: var(--spacing-5);
  left: var(--spacing-5);
}

.user__cta {
  margin-top: 24px;
}

.discount {
  padding-top: var(--spacing-2);
  align-items: center;
}

.discount__field {
  display: flex;
}

.discount__field .input__field {
  width: 187px;
  margin-right: -1px;
}

@media only screen and (max-width: 767px) {
  .user {
    padding: 120px 0 0;
  }

  .user__section-container {
    flex-direction: column-reverse;
  }

  .user__img {
    flex: auto;
    width: 100px;
    height: 100px;
  }

  .subscription__header {
    flex-direction: column;
  }

  .subscription__detail {
    flex-direction: column;
    gap: var(--spacing-1);
    padding-bottom: var(--spacing-1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
  }

  .subscription__warning {
    margin-top: var(--spacing-2);
  }

  .discount {
    align-items: flex-start;
  }

  .discount__field {
    flex-direction: column;
  }

  .discount__field .input__field {
    width: 100%;
    margin-right: 0;
  }
}
