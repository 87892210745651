.toggle-select {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 40px;
}

.toggle-select__container {
  display: flex;
  padding: 3px;
  border: 1px solid white;
  border-radius: 2px;
  background-color: var(--glass);
  backdrop-filter: blur(4px);
}

.toggle-select__item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 11px 24px;
  border-radius: 2px;
  font-size: 1.8rem;
  line-height: 1.5;
  letter-spacing: -0.9px;
  font-weight: 600;
}

.toggle-select__item:hover {
  cursor: pointer;
}

.divider::after {
  content: '';
  position: absolute;
  top: 11px;
  bottom: 11px;
  right: 0;
  width: 1px;
  background-color: white;
}

.selected {
  color: var(--text);
  background-color: white;
  border-right: none;
}

@media only screen and (max-width: 899px) {
  .toggle-select {
    width: 100%;
  }

  .divider::after {
    left: 11px;
    right: 11px;
    top: auto;
    bottom: 0;
    width: auto;
    height: 1px;
  }

  .toggle-select__container {
    flex-direction: column;
  }

  .toggle-select__item {
    padding: 15px 16px;
  }
}
