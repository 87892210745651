.mission {
  display: flex;
  align-items: center;
  position: relative;
  padding: 200px 0;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
}

.mission__container {
  display: flex;
  gap: var(--spacing-10);
  margin-top: var(--spacing-5);
}

.mission__content,
.mission__usps {
  flex: 0 0 calc((100% - var(--spacing-10)) / 2);
}

.mission__cta {
  margin-top: var(--spacing-5);
}

.mission__usp {
  position: relative;
  display: flex;
  align-items: center;
  gap: var(--spacing-3);
}

.mission__usp:not(:first-of-type) {
  margin-top: var(--spacing-5);
}

.mission__usp-numb {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6.4rem;
  height: 6.4rem;
  border: 1px solid white;
  border-radius: 50%;
}

@media only screen and (max-width: 767px) {
  .mission {
    padding: var(--spacing-10) 0;
  }

  .mission__container {
    flex-direction: column;
    gap: var(--spacing-5);
    margin-top: var(--spacing-5);
  }

  .mission__cta {
    margin-top: var(--spacing-3);
  }

  .mission__usp:not(:first-of-type) {
    margin-top: var(--spacing-3);
  }

  .mission__usp-numb {
    width: 4.8rem;
    height: 4.8rem;
  }
}
