.message {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 24px;
  padding: 16px;
  width: 100%;
}

.message svg {
  flex: 0 0 auto;
  align-self: flex-start;
  width: 3.6rem;
  height: 3.6rem;
}

.message--success {
  background-color: var(--success);
  color: var(--success-text);
}

.message--warning {
  background-color: var(--warning);
  color: var(--warning-text);
}

.message--error {
  background-color: var(--error);
  color: var(--error-text);
}
