:root {
  --primary: #218ce7;
  --primary100: #d5dde9;
  --secondary: #ff3b4b;
  --text: #233b4b;
  --surface400: #1c2f3c;
  --surface300: #233c4b;
  --surface200: #e9ebed;
  --surface100: #f2f3f5;
  --success: #7be085;
  --success200: #2bb539;
  --success-text: #1b7324;
  --error: #eb6771;
  --error200: #cc0000;
  --error-text: #801717;
  --warning: #ffcb66;
  --warning200: #ea9a00;
  --warning-text: #ad7200;
  --border300: #cdd1d6;
  --color-brand-lime: #cae923;

  --glass: rgba(255, 255, 255, 0.01);
  --glass100: rgba(255, 255, 255, 0.1);

  --spacing-1: 8px;
  --spacing-2: 16px;
  --spacing-3: 24px;
  --spacing-5: 40px;
  --spacing-10: 80px;
  --spacing-15: 120px;
  --spacing-20: 160px;
  --spacing-40: 320px;

  --transition: 300ms ease-out;

  --header-height: 88px;

  --red-gradient: linear-gradient(
    180deg,
    #b46c5f -9.86%,
    #d48349 20.8%,
    #bb7260 50.85%,
    #9a4b54 73.54%,
    #6b2a35 93.17%,
    #422e61 112.79%
  );
  --red2-gradient: linear-gradient(
    180deg,
    #b96645 -3.25%,
    #bc662b 25.62%,
    #9c4b4b 71.37%,
    #801f2c 100%
  );
  --green-gradient: linear-gradient(
    180deg,
    #c0c49f 0%,
    #a29c7b 25%,
    #8a908b 47.79%,
    #607a81 73.96%,
    #4a6871 100%
  );
  --blue5-gradient: linear-gradient(0deg, #c7c2c7 0%, #b8afaa 39.77%, #9097a4 75.1%, #7a92a0 100%);
  --blue4-gradient: linear-gradient(180deg, #688086 0%, #9c958b 57.7%, #beab92 100%);
  --blue3-gradient: linear-gradient(
    0deg,
    #59777e 0%,
    #677681 24.5%,
    #767986 49.78%,
    #604d65 80%,
    #463e58 100%
  );
  --blue-orange-gradient: linear-gradient(
    180deg,
    #63788a 0%,
    #888f9e 24.42%,
    #ad978f 49.52%,
    #bd8d6a 79.5%,
    #c76522 100%
  );
  --red-blue-gradient: linear-gradient(
    180deg,
    #7790a4 0%,
    #9ba6b8 21.34%,
    #b6b1ab 40.1%,
    #d0af96 58.43%,
    #cea180 71.69%,
    #cf7225 94.96%
  );
}

@media only screen and (max-width: 767px) {
  :root {
    --header-height: 80px;
  }
}
