.result__content {
  display: flex;
}

.result__title,
.result__info {
  flex: 0 0 calc((100% - var(--spacing-10)) / 2);
}

.result__info {
  align-items: flex-start;
}

.result__container {
  display: flex;
  flex-wrap: wrap;
}

.result__item {
  flex: 1 0 calc((100% - var(--spacing-5) * 3) / 4);
  background: rgba(255, 255, 255, 0.1);
}

@media only screen and (max-width: 1279px) {
  .result__content {
    flex-direction: column;
  }

  .result__title,
  .result__info {
    flex: 0 0 auto;
  }

  .result__item {
    flex: 0 0 calc((100% - var(--spacing-5)) / 2);
  }

  .result__item-title {
    min-height: 80px;
  }
}

@media only screen and (max-width: 767px) {
  .result__item {
    flex: 0 0 100%;
  }

  .result__item-title {
    min-height: unset;
  }
}
