.product {
  display: flex;
  align-items: center;
  position: relative;
  padding: 200px 0;
  min-height: 100vh;
}

.product__container {
  display: flex;
  gap: var(--spacing-10);
}

.product__content {
  flex: 1 1 calc((100% - var(--spacing-10)) / 2);
  width: 0;
  margin-left: auto;
}

.product__label {
  margin-bottom: 24px;
}

.product__desc,
.product__cta {
  margin-top: var(--spacing-5);
}

.product__images {
  flex: 1 1 calc((100% - var(--spacing-10)) / 2);
}

.product__images-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.product__image-container {
  padding: 16px 16px 60px;
  background-color: white;
  transition: var(--transition);
}

.product__image {
  width: 250px;
  height: 250px;
  object-fit: cover;
}

.product__image-container:nth-of-type(1) {
  transform: translate(-120px, 37px) rotate(4deg);
}

.product__image-container:nth-of-type(1):hover {
  transform: translate(-120px, 37px) scale(1.1);
}

.product__image-container:nth-of-type(2) {
  position: absolute;
  transform: translate(125px, -3px) rotate(-2deg);
}

.product__image-container:nth-of-type(2):hover {
  transform: translate(125px, -3px) scale(1.1);
}

.product__image-container:hover {
  z-index: 2;
}

@media only screen and (max-width: 1279px) {
  .product__container {
    flex-direction: column-reverse;
  }

  .product__content {
    flex: 0 0 auto;
    width: 100%;
    margin-left: 0;
  }

  .product__images {
    flex: 0 0 auto;
  }

  .product__images-container {
    flex-direction: row;
  }

  .product__image-container {
    flex-shrink: 0;
    padding: 12px 12px 40px;
  }

  .product__image {
    width: 155px;
    height: 155px;
  }

  .product__image-container:nth-of-type(1) {
    transform: translate(10px, 22px) rotate(4deg);
  }

  .product__image-container:nth-of-type(1):hover {
    transform: translate(10px, 22px) scale(1.1);
  }

  .product__image-container:nth-of-type(2) {
    transform: translate(145px, 2px) rotate(-2deg);
    z-index: 1;
  }

  .product__image-container:nth-of-type(2):hover {
    transform: translate(145px, 2px) scale(1.1);
  }
}

@media only screen and (max-width: 767px) {
  .product {
    padding: var(--spacing-10) 0;
  }

  .product__container {
    flex-direction: column-reverse;
    gap: var(--spacing-5);
  }

  .product__label {
    margin-bottom: 16px;
  }
}
