.testimonials {
  display: flex;
  align-items: center;
  min-height: 100vh;
  padding: 200px 0;
}

.testimonials__container {
  display: flex;
  flex-wrap: wrap;
  margin: var(--spacing-10) calc(var(--spacing-5) * -1) 0;
}

.testimonials__item {
  flex: 0 0 calc(100% / 3);
  width: 0;
  padding: var(--spacing-5);
}

.testimonials__quote {
  position: relative;
}

.testimonials__quote::before {
  content: '“';
  display: block;
  width: 3rem;
  height: 2.5rem;
  position: absolute;
  bottom: calc(100% + 3.8rem);
  right: calc(100% - 2.8rem);
  opacity: 0.1;
  font-size: 9rem;
  line-height: 4.2rem;
  line-height: 1;
  font-family: 'Neuton';
  z-index: -1;
}

.testimonials__item-desc {
  margin-top: var(--spacing-3);
}

@media only screen and (max-width: 999px) {
  .testimonials__item {
    flex: 0 0 calc(100% / 2);
  }
}

@media only screen and (max-width: 767px) {
  .testimonials {
    padding: 100px 0;
  }

  .testimonials__container {
    margin: 72px 0 0;
  }

  .testimonials__item {
    flex: 0 0 100%;
    padding: 0;
  }

  .testimonials__item:not(:last-of-type) {
    margin-bottom: 56px;
  }

  .testimonials__item-desc {
    margin-top: var(--spacing-2);
  }

  .testimonials__quote::before {
    bottom: calc(100% + 3.3rem);
    right: auto;
    left: 0;
  }
}
