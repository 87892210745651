.alert-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 200;
}

.alert {
  width: calc(100 * var(--vw, 1vw) - 40px);
  max-width: 500px;
  animation:
    fadeIn 0.5s ease,
    fadeOut 0.5s ease 4s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@media only screen and (max-width: 767px) {
  .alert-container {
    bottom: 16px;
    right: 16px;
    width: calc((var(--vw, 1vw) * 100) - 32px);
  }

  .alert {
    width: 100%;
    max-width: 500px;
  }
}
